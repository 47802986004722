import { FC } from 'react'
import styled from 'styled-components'
import Carousel from 'nuka-carousel'
import { ICarousel } from 'src/types'

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const VerticalCarouselWrapper = styled.div`
  width: 48%;
  height: 450px;

  .slider-slide {
    height: 450px !important;
  }
`

interface Props {
  verticalCarousels: ICarousel[]
}

const VerticalCarousel: FC<Props> = ({ verticalCarousels }) => (
  <VerticalCarouselWrapper>
    <Carousel
      autoplay
      autoplayInterval={2000}
      transitionMode="fade"
      wrapAround
      withoutControls
    >
      {verticalCarousels.slice(0, 10).map((image) => (
        <Img key={image.title} src={image.imageUrl} alt={image.title} />
      ))}
    </Carousel>
  </VerticalCarouselWrapper>
)

export default VerticalCarousel
