import { FC } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { formatJSONDate } from 'yancey-js-util'
import { IPost } from 'src/types'

const NewsWrapper = styled.section`
  width: 48%;
`

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
`

const Title = styled.p`
  margin: 10px 0;
  width: 70%;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
`

const DateTime = styled.span`
  font-size: 14px;
  color: #000;
`

const BigTitle = styled.h3`
  margin: 0;
  color: #000;
`

const Description = styled.p`
  color: #606060;
  font-size: 13px;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  p {
    text-indent: 0 !important;
  }
`

const BigTitleWrapper = styled.div`
  border-bottom: 1px #cccccc solid;
`

export const getText = (raw: string) => {
  const obj = JSON.parse(raw)
  let str = ''
  obj.blocks.forEach((val: any) => {
    if (val.text && val.text !== 'a') {
      str += val.text
    }
  })

  return str
}

interface Props {
  posts: IPost[]
}

const News: FC<Props> = ({ posts }) => {
  return (
    <NewsWrapper>
      <ul>
        {posts.map((post, key) => {
          const { _id, title, createdAt, contentRAW } = post
          return key === 0 ? (
            <Link href={`/post/${_id}`} key={_id}>
              <a>
                <BigTitleWrapper>
                  <BigTitle>{title}</BigTitle>
                  <Description>{getText(contentRAW)}</Description>
                </BigTitleWrapper>
              </a>
            </Link>
          ) : (
            <Link href={`/post/${_id}`} key={_id}>
              <a>
                <Item>
                  <Title>{title}</Title>
                  <DateTime>{formatJSONDate(createdAt).split(' ')[0]}</DateTime>
                </Item>
              </a>
            </Link>
          )
        })}
      </ul>
    </NewsWrapper>
  )
}

export default News
