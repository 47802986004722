import { FC } from 'react'
import Layout from 'src/containers/Layout/Layout'
import HomeContainer from 'src/containers/Home/Home'

const Index: FC = () => (
  <Layout>
    <HomeContainer />
  </Layout>
)

export default Index
