import { FC } from 'react'
import { useQuery } from 'react-query'
import SubTitle from 'src/components/SubTitle/SubTitle'
import {
  fetchArtists,
  fetchWorks,
  fetchHorizontalCarousels,
  fetchVerticalCarousels,
  fetchPosts,
} from 'src/services'
import { Category } from 'src/types'
import HorizontalCarousel from './components/HorizontalCarousel'
import VerticalCarousel from './components/VerticalCarousel'
import News from './components/News'
import About from './components/About'
import Artist from './components/Artist'
import Works from './components/Works'
import { HomeContainer, NewsWrapper, NewsContainer, NewsBoth } from './styled'

const Home: FC = () => {
  const { data: artistsQuery } = useQuery('artists', fetchArtists)
  const { data: worksQuery } = useQuery('works', fetchWorks)
  const { data: horizontalCarouselsQuery } = useQuery(
    'horizontalCarousels',
    fetchHorizontalCarousels,
  )
  const { data: verticalCarouselsQuery } = useQuery(
    'verticalCarousels',
    fetchVerticalCarousels,
  )
  const { data: postsQuery } = useQuery('posts', () =>
    fetchPosts(Category.News, 1),
  )
  return (
    <HomeContainer>
      <HorizontalCarousel
        horizontalCarousels={horizontalCarouselsQuery || []}
      />

      <NewsContainer>
        <NewsBoth>
          <SubTitle title="最新动态" />
          <NewsWrapper>
            <VerticalCarousel
              verticalCarousels={verticalCarouselsQuery || []}
            />
            <News posts={postsQuery ? postsQuery.posts : []} />
          </NewsWrapper>
        </NewsBoth>
        <About />
      </NewsContainer>

      <Artist artists={artistsQuery || []} />
      <Works works={worksQuery || []} />
    </HomeContainer>
  )
}

export default Home
