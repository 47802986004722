import { FC } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import SubTitle from 'src/components/SubTitle/SubTitle'
import { IWork } from 'src/types'

const ArtistWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Image = styled.img`
  width: 230px;
  height: 160px;
`

export interface Props {
  works: IWork[]
}

const Works: FC<Props> = ({ works }) => {
  return (
    <section>
      <SubTitle title="作品展示" />
      <ArtistWrapper>
        {works.slice(0, 5).map((work) => (
          <Link href={`work/${work._id}`} key={work._id}>
            <a>
              <Image src={work.imageUrl} alt={work.name} />
            </a>
          </Link>
        ))}
      </ArtistWrapper>
    </section>
  )
}

export default Works
