import styled from 'styled-components'

export const HomeContainer = styled.section`
  position: relative;
`

export const NewsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const NewsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const NewsBoth = styled.div`
  width: 67%;
`
